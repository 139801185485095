import api from "../axios_service.js";

export async function getVendas(filtros) {
  const response = await api.get(`/vendas${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function getVendasAll(filtros) {
  const response = await api.get(`/vendas-all${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function vendaPdf(id) {
  const response = await api.get(`/venda-pdf/${id}`);
  return response.data.result;
}
export async function fetchVenda(id) {
  const response = await api.get(`/vendas/${id}`);
  return response.data.result;
}

export async function postVenda(body) {
  const response = await api.post("/venda", body);
  return response.data;
}

export async function postVendaPiscina(body) {
  const response = await api.post("/venda-piscina", body);
  return response.data;
}

export async function putVenda(id, venda) {
  return api.post(`/vendas/${id}`, venda);
}

export async function putVendaPiscina(id, venda) {
  return api.post(`/vendas-piscina/${id}`, venda);
}

export function enviarCaixa(id, venda) {
  return api.post(`/enviar-caixa/${id}`, venda);
}

export async function cancelVenda(id, venda) {
  return api.put(`/cancel-venda/${id}`, venda);
}

export async function cancelVendaFinalizada(id, venda) {
  return api.put(`/cancel-venda-finalizada/${id}`, venda);
}

export async function getVendasCaixa(clienteId) {
  const queryParams = clienteId ? `?cliente_id=${clienteId}` : '';
  const response = await api.get(`/vendas-caixas${queryParams}`);
  return response.data.result;
}

export async function getFinalVendasCaixa(id) {
  const response = await api.get(`/vendas-caixas-final/${id}`);
  return response.data.result;
}

export function iniciaBaixaVenda(id, venda) {
  return api.post(`/inicia-baixa-venda/${id}`, venda);
}
export function infoPagareVendaFinalizada(id, venda) {
  return api.post(`/info-pagare-venda-finalizada/${id}`, venda);
}
export function fechaBaixaVenda(id) {
  return api.post(`/fecha-baixa-venda/${id}`);
}
export function retornaVenda(id, body) {
  return api.post(`/retorna-venda/${id}`, body);
}

export async function faturaVenda(venda) {
  return await api.post(`/faturar-venda`, venda);
}

export async function faturaVendaPiscina(venda) {
  return await api.post(`/faturar-venda-piscina`, venda);
}

export async function getIntervalosParcelas() {
  const response = await api.get(`/intervalos-parcelas-vendas`);
  return response.data.result;
}

export async function fetchVendaDevolucao(id) {
  const response = await api.get(`/venda-devolucao/${id}`);
  return response.data.result;
}

export async function fetchTicket(id) {
  const response = await api.get(`/venda-ticket/${id}`);
  return response.data.result;
}

export async function getVendaIdByOrdemServicoId(ordem_servico_id) {
  const response = await api.get(
    `/get-venda-id-by-ordem-servico-id/${ordem_servico_id}`
  );
  return response.data.result;
}

export async function getVendaInfo(venda_id) {
  const response = await api.get(`/get-venda-info/${venda_id}`);
  return response.data.result;
}

export async function checkPrecoItems(body) {
  const response = await api.post(`/check-preco-items`, body);
  return response.data.result;
}
